import React, { useRef, useState } from 'react'
import Container from '@material-ui/core/Container';
import Header from '../../components/reusable/_header';
import Footer from '../../components/reusable/_footer';
import { HomePageStyles } from '../../_styles/mui';
import Button from '@material-ui/core/Button';
import { NavLink } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import { handleInputChange } from '../../_utils/_handlers';
import AboutComponent from '../../components/reusable/about';

const useStyles = (theme) => HomePageStyles(theme)

function IndexContainer() {
    const classes = useStyles();
    const formRef = useRef(null)
    const [payload, setPayload] = useState({
        error: {},
        fields: {}
    })

    const inputHandler = e => {
        handleInputChange(e, cb => {
            setPayload({
                ...payload,
                fields: {
                    ...payload.fields,
                    [cb.name]: cb.value
                }
            })
        })
    }

    const submitForm = e => {
        // form integration left and error handling
        e.preventDefault()
        let $el = document.getElementById("submitFormButton")
        $el.setAttribute("disabled", "disabled")
        $el.innerHTML = "Submitting.."
        setTimeout(() => {
            $el.removeAttribute("disabled")
            $el.innerHTML = "SUBMIT"
            formRef.current.reset()
        }, 1000);
    }

    return (
        <React.Fragment>
            <Header/>
            <div className={classes.homeCoverImage}>
                <div className="container h-inherit">
                    <div className="top--cover h-inherit">
                        <div className="top--cover_wrap">
                            <h1 className="fsize-60">You Have The</h1>
                            <h1 className="fsize-60">Power To Bring Happiness</h1>
                            <div className="col-md-10 offset-md-1 col-12">
                                <h4 className="fsize-28">
                                    We connect various individuals to bring a positive change 
                                    in people's lives.
                                </h4>
                            </div>
                            <div className="d-flex">
                                <NavLink to={"/login"} className={`${classes.defaultNavLink} my-4 mx-auto`}>
                                    <Button variant="contained" 
                                    className={`${classes.themeButton} ${classes.lightThemeButton} ${classes.primaryColorText} 
                                    extra-padding ${classes.colorThemeButton} transform-initial`}>
                                        Get Started!
                                    </Button>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Container>
                <div className="hiw--section">
                    <div className="hiw--section_wrap">
                        <h1>
                            How It <span className={classes.primaryColorText}>Works</span>
                        </h1>
                        <div className="col-md-10 offset-md-1 col-12">
                            <h5 className={classes.lgColorText}>
                                DigiDonor aims to eliminate cash handouts and make the process of donating more 
                                efficient and transparent
                            </h5>
                        </div>
                        <div className="row my-5">
                            <div className="col-md-4 col-sm-6 col-12 mb-4">
                                <div className={`${classes.cardWrap} card`}>
                                    <div className="d-flex flex-column card-body">
                                        <div className="img-wrap">
                                            <img src={`/assets/icons/qr-code.svg`} className="img-fluid" alt="QrCode"/>
                                        </div>
                                        <h5>Scan Voucher</h5>
                                        <p>
                                            Scan the Voucher to donate or receive. You 
                                            can also receive money for Charity.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6 col-12 mb-4">
                                <div className={`${classes.cardWrap} card`}>
                                    <div className="d-flex flex-column card-body">
                                        <div className="img-wrap">
                                            <img src={`/assets/icons/support.svg`} className="img-fluid" alt="QrCode"/>
                                        </div>
                                        <h5>Donate Amount</h5>
                                        <p>
                                            Donating to the causes you care about not only 
                                            benefits the charity themselves, it can be 
                                            deeply rewarding for you too.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6 col-12 mb-4">
                                <div className={`${classes.cardWrap} card`}>
                                    <div className="d-flex flex-column card-body">
                                        <div className="img-wrap">
                                            <img src={`assets/icons/store.svg`} className="img-fluid" alt="QrCode"/>
                                        </div>
                                        <h5>Redeem Voucher</h5>
                                        <p>
                                            You can redeem the voucher at your nearest 
                                            stores. This will lead to helping of thousands in 
                                            need.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
            <div className="divider">
                <div className="divider-row"></div>
            </div>
            <Container>
                <div className="split--section">
                    <div className="split--section_wrap d-flex flex-md-row flex-column">
                        <div className="col-lg-5 order-md-1 order-2">
                            <h1>
                                Your Donation is <span className={classes.primaryColorText}>Really Powerful</span>
                            </h1>
                            <h5 className={classes.lgColorText}>
                                Donating to the causes you care about not only benefits 
                                the charities themselves, it can be deeply rewarding for you too.
                                Millions of People give to charity on a regular basis to support causes they 
                                believe in, as well as for the positive effect it has on their own lives.
                            </h5>
                            <h5 className={`${classes.lgColorText} pt-4`}>
                                Be a Part of the DigiDonor breakthrough and see someone's life change and their dreams 
                                come true. The knowledge that you're helping others is hugely empowering and, in turn, 
                                can make you feel happier and more fulfilled.
                            </h5>
                        </div>
                        <div className="col-lg-7 order-md-2 order-1 px-md-0">
                            <div className="img-wrap mb-4 mb-lg-0 pb-4 pb-lg-0">
                                <img src={`assets/images/home_section-2.png`} className="img-fluid" alt="Donation is Really Powerful"/>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
            <div className="divider">
                <div className="divider-row"></div>
            </div>
            {/* <Container>
                <div className="split--section">
                    <div className="split--section_wrap d-flex flex-md-row flex-column">
                        <div className="col-lg-5 px-0 mb-4 pb-2 pb-md-0 mb-md-0">
                            <div className="img-wrap">
                                <img className="img-fluid max-100" src={`/assets/images/home_section-3.png`} alt="Download App"/>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="d-flex h-100">
                                <div className="my-auto">
                                    <h1>
                                        Download The <span className={classes.primaryColorText}>DigiDonor App</span>
                                    </h1>
                                    <h5 className={classes.lgColorText}>
                                        Donating to the causes you care about not only benefits 
                                        the charities themselves, it can be deeply rewarding for you too.
                                        Millions of People give to charity on a regular basis to support causes they 
                                        believe in, as well as for the positive effect it has on their own lives.
                                    </h5>
                                    <div className="d-flex flex-wrap">
                                        <a className={`${classes.defaultNavLink} storeBtn`} href="https://google.com/" rel="follow">
                                            <div className="d-flex align-items-center">
                                                <div className="img-wrap">
                                                    <img src={`/assets/icons/playstore.svg`} className="img-fluid" alt="Google playstore"/>
                                                </div>
                                                <div className="d-flex flex-column ml-3">
                                                    <h6>GET IT ON</h6>
                                                    <h3>Google Play</h3>
                                                </div>
                                            </div>
                                        </a>
                                        <a className={`${classes.defaultNavLink} storeBtn`} href="https://google.com/" rel="follow">
                                            <div className="d-flex align-items-center">
                                                <div className="img-wrap">
                                                    <img src={`/assets/icons/apple_logo.svg`} className="img-fluid" alt="Apple playstore"/>
                                                </div>
                                                <div className="d-flex flex-column ml-3">
                                                    <h6>Download on the</h6>
                                                    <h3>App Store</h3>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container> */}
            <div className="divider">
                <div className="divider-row"></div>
            </div>
            {/* <Container>
                <div className="split--section">
                    <div className="split--section_wrap d-flex flex-md-row flex-column">
                        <div className="col-lg-5 mb-4 mb-md-0">
                            <h1>
                                Contact <span className={classes.primaryColorText}>Us</span>
                            </h1>
                            <h5 className={classes.lgColorText}>
                                Write to us about your experience with <span className={classes.primaryColorText}>DigiDonor</span> or 
                                Send us your queries about the platform.
                            </h5>
                        </div>
                        <div className="col-lg-7 px-md-0">
                            <form ref={formRef} onSubmit={e =>submitForm(e)} className="form">
                                <div className="w-100 mb-4 pb-1">
                                    <TextField name="name" type="text" onChange={inputHandler} required
                                    id="outlined-basic" label="Your Name Here" 
                                    variant="outlined" className={classes.textField}/>
                                </div>
                                <div className="w-100 mb-4 pb-1">
                                    <TextField name="email" type="email" onChange={inputHandler} required
                                    id="outlined-basic" label="Your Email Here" 
                                    variant="outlined" className={classes.textField}/>
                                </div>
                                <div className="w-100 mb-4 pb-1">
                                    <TextField name="message" type="text" onChange={inputHandler} required
                                    id="outlined-basic" label="Message" 
                                    multiline rows={6} variant="outlined" 
                                    className={classes.textField}/>
                                </div>
                                <div className="btn-wrap d-flex justify-content-between w-100">
                                    <div id="formResponse"></div>
                                    <Button type="submit" id="submitFormButton"
                                    variant="contained" className={`${classes.themeButton} transform-initial ml-auto extra-padding br-2`}>
                                        Submit
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Container> */}
            <AboutComponent/>
            <Footer/>
        </React.Fragment>
    )
}

export default IndexContainer